import axios from 'axios'
import decode from 'jwt-decode'
import Cookie from 'universal-cookie'

import { refreshToken } from '../../store/auth/actions'

import handleSubmissionError from './SubmissionError'
// Utils

const cookies = new Cookie()

export const isTokenExpired = (token: string) => {
  try {
    const decoded: any = token && decode(token)
    if (!token || (decoded && decoded.exp < Date.now() / 1000)) {
      // Checking if token is expired.
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

export const POST_MESSAGE_TYPE = {
  NAVIGATION: 'navigation',
  IFRAME_URL_CHANGE: 'iframeUrlChange',
  OPEN_TERMS: 'openTerms',
  IFRAME_MFA: 'iframeMFA',
  IFRAME_PASSKEY: 'iframePasskey',
  RESIZE: 'resize',
  PASSKEY_CREATE: 'passkeyCreate',
  PASSKEY_LOGIN: 'passkeyLogin',
  WEB_APP_PASSKEY_CREATE: 'webAppPasskeyCreate',
  WEB_APP_PASSKEY_LOGIN: 'webAppPasskeyLOGIN',
  SSO_REDIRECT_PENDING: 'ssoRedirectPending',
}

export default {
  setupInterceptors: (store) => {
    axios.interceptors.request.use(
      async (config) => {
        const isExpired = isTokenExpired(cookies.get('access_token'))
        if (
          isExpired === true &&
          config?.url?.search('auth/token') === -1 &&
          cookies.get('refresh_token')
        ) {
          await store.dispatch(refreshToken({ refresh_token: cookies.get('refresh_token') }))
          config.headers.Authorization = `${cookies.get('token_type')} ${cookies.get(
            'access_token',
          )}`
        }

        return config
      },
      // Do something with request error
      (error) => Promise.reject(error),
    )
    axios.interceptors.response.use(null as any, (error) => {
      if (error.response.status === 422) {
        handleSubmissionError(error.response.data.errors)
      }

      return Promise.reject(error.response)
    })
  },
}
